import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BottomMenuContainer, MenuButton } from './styles';
import { TbSwipe } from "react-icons/tb";
import { HiOutlineChat } from "react-icons/hi";
import { CgProfile } from "react-icons/cg";
import { useTheme } from 'styled-components';

const BottomMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const getIconColor = (path) => {
    if (path === '/profile') {
      // Check for both '/profile' and '/login' paths
      return (location.pathname === '/profile' || location.pathname === '/login')
        ? theme.colors.primary
        : theme.colors.mediumGray;
    }
    return location.pathname === path ? theme.colors.primary : theme.colors.mediumGray;
  };

  return (
    <BottomMenuContainer>
      <MenuButton
        onClick={() => handleNavigation('/quiz')}
        active={location.pathname === '/quiz'}
      >
        <TbSwipe size={27} color={getIconColor('/quiz')} />
      </MenuButton>
      <MenuButton
        onClick={() => handleNavigation('/chat')}
        active={location.pathname === '/chat'}
        center
      >
        <HiOutlineChat size={27} color={getIconColor('/chat')} />
      </MenuButton>
      <MenuButton
        onClick={() => handleNavigation('/profile')}
        active={location.pathname === '/profile' || location.pathname === '/login'}
      >
        <CgProfile size={27} color={getIconColor('/profile')} />
      </MenuButton>
    </BottomMenuContainer>
  );
};

export default BottomMenu;