import React, { useState } from 'react';
import apiCall from '../../utils/api';
import { useAuth } from '../../contexts/AuthContext';
import { CenteredContainer, FormContainer, Input, Button, ErrorMessage } from './styles';

const PseudonymForm = () => {
  const [pseudonym, setPseudonym] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user, login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const updatedUser = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/auth/update-pseudonym/`,
        'POST',
        { pseudonym },
        {
          'Authorization': `Bearer ${user.token}`
        }
      );

      if (updatedUser && updatedUser.token) {
        login(updatedUser.token, user.method, false);
      } else {
        login(user.token, user.method, false);
      }
    } catch (error) {
      console.error('Error updating pseudonym:', error);
      setError('Failed to update pseudonym. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <CenteredContainer>
      <FormContainer onSubmit={handleSubmit}>
        <Input
          type="text"
          value={pseudonym}
          onChange={(e) => setPseudonym(e.target.value)}
          placeholder="Ecris ton pseudo"
          required
        />
        <Button type="submit" disabled={loading}>
          {loading ? 'Enregistrement...' : 'Enregistrer'}
        </Button>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </FormContainer>
    </CenteredContainer>
  );
};

export default PseudonymForm;
