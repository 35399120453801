import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import {
    getAuthToken,
    setAuthToken,
    setUser as setLocalUser,
    logout as authLogout,
    isTokenValid,
    setRefreshToken,
    getRefreshToken,
    refreshAuthToken,
} from '../utils/auth';
import apiCall from '../utils/api';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUserState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    console.log('Logging out user');
    authLogout();
    setUserState(null);
    setError(null);
    setAuthToken(null);
    navigate('/login');
  }, [navigate]);

  const fetchUser = useCallback(async (token) => {
    console.log('Fetching user data');
    const maxRetries = 3;
    let retries = 0;

    const attemptFetch = async () => {
      try {
        const userData = await apiCall(`${process.env.REACT_APP_BACKEND_URL}/auth/user/`, 'GET', null, {
          'Authorization': `Bearer ${token}`
        });
        console.log('User data fetched successfully:', userData);
        setUserState(userData);
        setLocalUser(userData);
        setError(null);
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (retries < maxRetries) {
          retries++;
          console.log(`Retrying fetch (attempt ${retries})`);
          await new Promise(resolve => setTimeout(resolve, 1000 * retries));
          return attemptFetch();
        }
        setError('Failed to authenticate. Please try again.');
        handleLogout();
      }
    };

    try {
      await attemptFetch();
    } finally {
      setLoading(false);
    }
  }, [handleLogout]);

  useEffect(() => {
    const checkAuth = async () => {
      let token = getAuthToken();
      console.log(`Retrieved token from storage: ${token}`);
      console.log(`Is token valid: ${isTokenValid(token)}`);

      if (token && isTokenValid(token)) {
        await fetchUser(token);
      } else if (getRefreshToken()) {
        try {
          console.log('Access token expired, attempting to refresh');
          token = await refreshAuthToken();
          await fetchUser(token);
        } catch (error) {
          console.log('Token refresh failed, logging out');
          handleLogout();
        }
      } else {
        setLoading(false);
        if (token) {
          console.log('Token exists but is invalid and no refresh token, logging out');
          handleLogout();
        }
      }
    };

    checkAuth();
  }, [fetchUser, handleLogout]);

  const login = useCallback(
    async (tokens, method, isNewUser = false) => {
      const { access, refresh } = tokens;
      console.log('Token to store:', access);
      setAuthToken(access);
      setRefreshToken(refresh);
      setLoading(true);
      try {
        await fetchUser(access);
        console.log('User fetched successfully');
        setError(null);

        if (isNewUser) {
          console.log('Navigating to set pseudonym');
          navigate('/set-pseudonym');
        } else {
          console.log('Navigating to chat');
          navigate('/chat');
        }
      } catch (error) {
        console.error('Error logging in:', error);
        setError('Failed to log in. Please try again.');
      } finally {
        setLoading(false);
      }
    },
    [fetchUser, navigate]
  );

  const value = {
    user,
    loading,
    error,
    isAuthenticated: !!user && !!getAuthToken() && isTokenValid(getAuthToken()),
    token: getAuthToken(),
    login,
    logout: handleLogout
  };

    return (
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    );
  };

  export const AuthProviderWithNavigate = ({ children }) => {
    return (
      <AuthProvider>
        {children}
      </AuthProvider>
    );
  };

  export const useAuth = () => useContext(AuthContext);

  export default AuthContext;
