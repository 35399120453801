import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import PseudonymForm from '../PseudonymForm';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, UserInfo, LogoutButton, LogoutIcon, LoginButton } from './styles';
import LogoutConfirmation from '../LogoutConfirmationPopUp';

const Profile = () => {
  const { user, logout, loading } = useAuth();
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user && user.is_new_user && !user.pseudo) {
      // If it's a new user without a pseudonym, stay on this page
      // The PseudonymForm will be rendered
    } else if (user && user.pseudo && location.state?.from === 'login') {
      // Only redirect to chat if coming from login and pseudonym is set
      navigate('/chat', { replace: true });
    }
  }, [user, navigate, location]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleLogoutClick = () => {
    setShowLogoutConfirmation(true);
  };

  const handleLogoutConfirm = () => {
    logout();
    setShowLogoutConfirmation(false);
  };

  const handleLogoutCancel = () => {
    setShowLogoutConfirmation(false);
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <Container>
      {user ? (
        <>
          <LogoutButton onClick={handleLogoutClick}>
            <LogoutIcon />
          </LogoutButton>
          {user.pseudo ? (
            <UserInfo>
              <p>Bienvenue, {user.pseudo} !</p>
              <p>Email: {user.email}</p>
            </UserInfo>
          ) : (
            <PseudonymForm />
          )}
          <LogoutConfirmation
            isOpen={showLogoutConfirmation}
            onClose={handleLogoutCancel}
            onConfirm={handleLogoutConfirm}
          />
        </>
      ) : (
        <LoginButton onClick={handleLoginClick}>Login</LoginButton>
      )}
    </Container>
  );
};

export default Profile;