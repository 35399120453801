import { createGlobalStyle } from 'styled-components';
import theme from './theme';

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Poppins:wght@600&display=swap');

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: ${theme.fonts.main};
    font-size: ${theme.fontSizes.medium};
    color: ${theme.colors.text};
    background-color: ${theme.colors.background};
    line-height: 1.5;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${theme.fonts.heading};
    margin-bottom: ${theme.spacing.medium};
  }

  a {
    color: ${theme.colors.primary};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  button {
    cursor: pointer;
    font-family: ${theme.fonts.main};
    font-size: ${theme.fontSizes.medium};
    padding: ${theme.spacing.small} ${theme.spacing.medium};
    border: none;
    border-radius: 4px;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.lightText};
    &:hover {
      opacity: 0.9;
    }
    &:focus {
      outline: none;
    }
    -webkit-tap-highlight-color: transparent;
  }

  @media (max-width: ${theme.breakpoints.mobile}) {
    body {
      font-size: ${theme.fontSizes.small};
    }
    button, input[type="button"], input[type="submit"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
`;

export default GlobalStyles;