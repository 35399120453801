import styled from 'styled-components';

export const ChatHistoryContainer = styled.div`
  padding: ${props => props.theme.spacing.large};
  background-color: ${props => props.theme.colors.background};
  border-radius: ${props => props.theme.spacing.small};
  margin-top: ${props => props.theme.spacing.large};
`;

export const HistoryTitle = styled.h2`
  font-size: ${props => props.theme.fontSizes.large};
  font-weight: bold;
  margin-bottom: ${props => props.theme.spacing.medium};
  color: ${props => props.theme.colors.text};
`;

export const HistoryItem = styled.div`
  background-color: ${props => props.theme.colors.background};
  border-radius: ${props => props.theme.spacing.small};
  padding: ${props => props.theme.spacing.medium};
  margin-bottom: ${props => props.theme.spacing.small};
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.colors.background};
    opacity: 0.8;
  }
`;

export const RenameInput = styled.input`
  flex-grow: 1;
  padding: ${props => props.theme.spacing.small};
  margin-right: ${props => props.theme.spacing.small};
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.spacing.small};
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: ${props => props.theme.spacing.small};
  color: ${props => props.theme.colors.text};
  transition: color 0.2s ease;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

export const DiscussionName = styled.span`
  flex-grow: 1;
  margin-right: ${props => props.theme.spacing.medium};
`;