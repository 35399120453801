// src/utils/auth.js

// Function to check if the user is authenticated
export const isAuthenticated = () => {
  const token = getAuthToken();
  return !!token && isTokenValid(token) && getUser();
};

// Function to set the authentication token
export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem('authToken', token);
  } else {
    localStorage.removeItem('authToken');
  }
};

// Function to set the refresh token
export const setRefreshToken = (refreshToken) => {
  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  } else {
    localStorage.removeItem('refreshToken');
  }
};

// Function to retrieve the authentication token
export const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

// Function to retrieve the refresh token
export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken');
};

// Function to refresh the auth token
export const refreshAuthToken = async () => {
  const refreshToken = getRefreshToken();
  if (refreshToken) {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/token/refresh/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh: refreshToken }),
      });
      if (response.ok) {
        const data = await response.json();
        const newToken = data.access;
        setAuthToken(newToken);
        return newToken;
      } else {
        throw new Error('Failed to refresh token');
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }
  } else {
    throw new Error('No refresh token available');
  }
};

// Modify the logout function to remove the refresh token
export const logout = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('refreshToken'); // Add this line
  localStorage.removeItem('user');
  localStorage.clear();
};


export const isTokenValid = (token) => {
  if (!token) return false;

  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const expirationTime = payload.exp * 1000; // Convert to milliseconds
    const bufferTime = 60 * 1000; // Refresh 1 minute before expiry
    return Date.now() < (expirationTime - bufferTime);
  } catch (error) {
    console.error('Error parsing token:', error);
    return false;
  }
};

export const setUser = (user) => {
  if (user) {
    localStorage.setItem('user', JSON.stringify(user));
  } else {
    localStorage.removeItem('user');
  }
};

export const getUser = () => {
  const userStr = localStorage.getItem('user');
  return userStr ? JSON.parse(userStr) : null;
};
