// styles.js
import styled from 'styled-components';

export const WelcomeMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.medium};
  background-color: ${({ theme }) => theme.colors.veryLightGray};
  width: 100%;
  flex: 1;
  max-width: ${(props) =>
  props.isSideMenuOpen && !props.isMobile ? 'calc(100% - 300px)' : '800px'};
  margin: 0 auto;
  overflow: hidden; /* Prevent scrolling */

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
    overflow: hidden; /* Prevent scrolling on mobile */
    max-width: 100%;
    padding: ${({ theme }) => theme.spacing.medium};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }
`;

export const AppLogo = styled.img`
  max-width: 80px;
  max-height: 60px;
  width: auto;
  height: auto;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  object-fit: contain;
`;

export const WelcomeMessage = styled.h2`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
`;

export const WelcomeDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  text-align: center;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.text};
  max-width: 80%;
`;

export const ExamplesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Maintain 2 columns */
  gap: ${({ theme }) => theme.spacing.small};
  justify-content: center;
  align-items: stretch;
  margin-top: ${({ theme }) => theme.spacing.medium};
`;

export const ExampleBox = styled.div`
  padding: ${({ theme }) => theme.spacing.medium};
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
  text-align: left;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  min-height: 60px;
  overflow: hidden;
  font-size: ${({ theme }) => theme.fontSizes.small};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: none; /* Remove purple edges */

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGray};
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;
