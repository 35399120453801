import React from 'react';
import { ModalOverlay, ModalContent, ModalTitle, ModalButtons, ConfirmButton, CancelButton } from './styles';

const LogoutConfirmation = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>Are you sure you want to log out?</ModalTitle>
        <ModalButtons>
          <ConfirmButton onClick={onConfirm}>Yes, Log Out</ConfirmButton>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
        </ModalButtons>
      </ModalContent>
    </ModalOverlay>
  );
};

export default LogoutConfirmation;