// PWAInstallPrompt.js
import React from 'react';
import { PWAPromptContainer, PWAPromptContent, PWAPromptTitle, PWAPromptText, PWAPromptButton } from './styles';

const PWAInstallPrompt = ({ onClose }) => {
  const handleInstall = () => {
    // Trigger the install prompt here
    // This will depend on how you've set up the deferredPrompt in your main App component
    if (window.deferredPrompt) {
      window.deferredPrompt.prompt();
      window.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        window.deferredPrompt = null;
      });
    }
    onClose();
  };

  return (
    <PWAPromptContainer>
      <PWAPromptContent>
        <PWAPromptTitle>Install our app</PWAPromptTitle>
        <PWAPromptText>Add our app to your home screen for a better experience!</PWAPromptText>
        <PWAPromptButton onClick={handleInstall}>Install</PWAPromptButton>
        <PWAPromptButton onClick={onClose}>Not now</PWAPromptButton>
      </PWAPromptContent>
    </PWAPromptContainer>
  );
};

export default PWAInstallPrompt;