// src/theme.js
const theme = {
  colors: {
    primary: '#bc00dd',
    secondary: '#bc00dd',
    accent: '#C965DD',
    background: '#FFF7F7',
    text: '#333333',
    lightText: '#ffffff',
    lightGray: '#FFEFEE',
    veryLightGray: '#FFF7F7',
    mediumGray: '#C965DD',
  },
  fonts: {
    main: "'Inter', sans-serif",
    heading: "'Inter', sans-serif",
  },
  fontSizes: {
    xsmall: '0.75rem',  // New xsmall size
    small: '0.875rem',
    medium: '1rem',
    large: '1.125rem',
    xlarge: '1.25rem',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  spacing: {
    xsmall: '0.25rem',  // New xsmall spacing
    small: '0.5rem',
    medium: '1rem',
    large: '1.5rem',
    xlarge: '2rem',
  },
  breakpoints: {
    mobile: '767px',
  },
};

export default theme;