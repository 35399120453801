import styled from 'styled-components';

export const QuizContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.background};
`;

export const QuizMessage = styled.h2`
  font-size: ${props => props.theme.fontSizes.xlarge};
  color: ${props => props.theme.colors.primary};
  text-align: center;
  padding: ${props => props.theme.spacing.large};
`;