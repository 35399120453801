import styled from 'styled-components';

export const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: ${props => props.theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.xlarge};
  width: 40px;
  height: 40px;
  transition: opacity 0.3s ease;

  -webkit-tap-highlight-color: transparent;

    &:hover {
      opacity: 0.8;
    }

    &:active, &:focus {
      outline: none;
    }
  `;