import React, { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import axios from 'axios';
import rehypeExternalLinks from 'rehype-external-links'
import { getAuthToken } from '../../utils/auth';
import { ClipLoader } from 'react-spinners';
import WelcomeMenu from '../WelcomeMenu';
import {
  Container,
  ChatContainer,
  ChatBox,
  ChatHistory,
  Message,
  InputArea,
  Input,
  Button,
  MarkdownBox,
  InputAreaWrapper,
  MenuBand,
  MenuButton,
} from './styles';
import { FaArrowUp } from "react-icons/fa";
import { FaRegPlusSquare } from "react-icons/fa";
import { useAuth } from '../../contexts/AuthContext';
import PopUp from '../SaveLoginPopup';
import MobileHistoryTab from '../MobileHistoryTab';
import useDeviceType from '../useDeviceType';

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ChatDiscussion = forwardRef(({ onStartNewChat, toggleMobileHistory, isSideMenuOpen }, ref) => {
  const location = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [inputText, setInputText] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [discussion, setDiscussion] = useState('');
  const [, setContextHistory] = useState([]);
  const [, setBeginDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const chatHistoryRef = useRef(null);
  const [isMobileHistoryOpen, setIsMobileHistoryOpen] = useState(false);
  const [, setIsHistoryOpen] = useState(false);
  const { user, isAuthenticated } = useAuth();
  const [streamingMessage, setStreamingMessage] = useState('');
  const streamingMessageRef = useRef(null);
  const [showWelcomeMenu, setShowWelcomeMenu] = useState(true);
  const { isMobile } = useDeviceType();
  const isUnmounting = useRef(false);

  useEffect(() => {
    isUnmounting.current = false;
    return () => {
      isUnmounting.current = true;
    };
  }, []);

  useEffect(() => {
    const scrollToBottom = () => {
      if (chatHistoryRef.current) {
        chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
      }
    };

    scrollToBottom();

    const observer = new MutationObserver(scrollToBottom);
    if (chatHistoryRef.current) {
      observer.observe(chatHistoryRef.current, { childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, [chatHistory, streamingMessage]);

  useImperativeHandle(ref, () => ({
    resetChat: handleStartNewChat,
    loadDiscussion: loadDiscussion
  }));

  const toggleHistory = () => {
    if (isMobile) {
      setIsMobileHistoryOpen(prev => !prev);
    } else {
      setIsHistoryOpen(prev => !prev);
    }
  };

  const adjustTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      const newHeight = Math.min(inputRef.current.scrollHeight, 150);
      inputRef.current.style.height = `${newHeight}px`;

      // Adjust the textarea's position within its container
      inputRef.current.style.top = `${150 - newHeight}px`;
    }
  };



  useEffect(() => {
    adjustTextareaHeight();
  }, [inputText]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputText(newValue);
    adjustTextareaHeight();
  };

  // Wrap loadDiscussion in useCallback
  const loadDiscussion = useCallback(async (discussionId) => {
    setIsLoading(true);
    try {
      const token = getAuthToken();
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/inference/get_single_discussion/`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          params: { discussion_id: discussionId },
        }
      );

      console.log('Response status:', response.status);
      console.log('Response data:', response.data);

      if (response.status === 200) {
        const { messages, last_context, begin_date } = response.data;
        setChatHistory(messages.map(msg => ({ user: msg.is_user ? 'You' : 'Bot', message: msg.content })));
        setContextHistory(last_context);
        setBeginDate(begin_date);
        setDiscussion(discussionId);
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error loading discussion:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!isUnmounting.current) {
      const stateToPersist = {
        chatHistory,
        discussion,
        inputText,
      };
      localStorage.setItem('chatDiscussionState', JSON.stringify(stateToPersist));
    }
  }, [chatHistory, discussion, inputText]);


  useEffect(() => {
    const loadPersistedState = () => {
      const persistedState = localStorage.getItem('chatDiscussionState');
      if (persistedState) {
        const {
          chatHistory: savedHistory,
          discussion: savedDiscussion,
          inputText: savedInput,
        } = JSON.parse(persistedState);
        setChatHistory(savedHistory || []);
        setDiscussion(savedDiscussion || '');
        setInputText(savedInput || '');
        setShowWelcomeMenu((savedHistory || []).length === 0);
      }
    };

    loadPersistedState();

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        loadPersistedState();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const handleStartNewChat = useCallback(() => {
    if (!isAuthenticated) {
      setIsPopUpOpen(true);
      return;
    }

    setChatHistory([]);
    setDiscussion('');
    setContextHistory('');
    setBeginDate('');
    setShowWelcomeMenu(true);
    localStorage.removeItem('chatDiscussionState');  // Clear the chat state from localStorage
  }, [isAuthenticated]);

  useEffect(() => {
    if (location.state?.selectedDiscussion) {
      loadDiscussion(location.state.selectedDiscussion.id);
      setShowWelcomeMenu(false);
    }
  }, [location.state, loadDiscussion]);

  const saveDiscussionToLocalStorage = (newDiscussion) => {
    const savedDiscussions = JSON.parse(localStorage.getItem('chatDiscussions')) || [];
    const updatedDiscussions = [...savedDiscussions, newDiscussion];
    localStorage.setItem('chatDiscussions', JSON.stringify(updatedDiscussions));
  };

  const handleExampleClick = (example) => {
    setInputText(example);
    handleSubmit(null, example);
  };

  const handleSubmit = async (e, exampleText = null) => {
    // Prevent default form submission only if it's a real event
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const messageToSend = exampleText || inputText.trim();

    if (!messageToSend) {
      alert('Please enter a valid message before sending.');
      return;
    }

    if (!isAuthenticated) {
      setIsPopUpOpen(true);
      return;
    }

    setShowWelcomeMenu(false); // Hide welcome menu when submitting a message

    const newUserMessage = { user: 'You', message: messageToSend };
    const updatedUserChatHistory = [...chatHistory, newUserMessage];

    setChatHistory(updatedUserChatHistory);
    setInputText('');
    setIsLoading(true);
    setStreamingMessage('');

    try {
      let currentDiscussion = discussion;

      if (!currentDiscussion) {
        const newDiscussionResponse = await axios.post(`${REACT_APP_BACKEND_URL}/inference/start_new_discussion/`, {
          user_id: user.id,
        }, {
          withCredentials: true
        });

        if (newDiscussionResponse.status === 200) {
          currentDiscussion = newDiscussionResponse.data.new_discussion_id;
          setDiscussion(currentDiscussion);
        } else {
          throw new Error('Failed to create a new discussion');
        }
      }

      // API call and response handling
      const response = await fetch(`${REACT_APP_BACKEND_URL}/inference/inference_view/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getAuthToken()}`,
        },
        body: JSON.stringify({
          input: messageToSend,
          discussion: currentDiscussion,
          user_id: user.id,
        }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let fullResponse = '';
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n\n');
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const data = JSON.parse(line.slice(6));
            if (data.chunk === '[END]') {
              // Stream is complete, update the chat history
              const newBotMessage = { user: 'Bot', message: fullResponse };
              setChatHistory(prev => [...prev, newBotMessage]);
              setStreamingMessage('');

              // Save to local storage
              const newDiscussionEntry = {
                title: `Chat from ${new Date().toLocaleString()}`,
                content: [...updatedUserChatHistory, newBotMessage]
              };
              saveDiscussionToLocalStorage(newDiscussionEntry);

              return;
            }
            fullResponse += data.chunk;
            setStreamingMessage(fullResponse);
          }
        }
      }

    } catch (error) {
      console.error('Error during inference:', error);
      setChatHistory(prevHistory => [...prevHistory, { user: 'Bot', message: "" }]);
    } finally {
      setIsLoading(false);
    }
  };


  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <Container isSideMenuOpen={!isMobile && isSideMenuOpen} isMobile={isMobile}>
      {isMobile && (
        <MenuBand>
          <MenuButton onClick={toggleHistory}>
          </MenuButton>
          <MenuButton onClick={handleStartNewChat}>
            <FaRegPlusSquare size={24} />
          </MenuButton>
        </MenuBand>
      )}
      {isMobile && (
        <MobileHistoryTab
          isOpen={isMobileHistoryOpen}
          onClose={toggleHistory}
        />
      )}
      <ChatContainer showWelcomeMenu={showWelcomeMenu} isMobile={isMobile} isSideMenuOpen={!isMobile && isSideMenuOpen}>
        <ChatBox>
          <ChatHistory ref={chatHistoryRef}>
            {showWelcomeMenu ? (
              <WelcomeMenu
                onExampleClick={handleExampleClick}
                isSideMenuOpen={!isMobile && isSideMenuOpen}
                isMobile={isMobile}
              />
            ) : (
              <>
                {chatHistory.map((chat, index) => (
                  <Message key={index} $user={chat.user}>
                    <MarkdownBox>
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[
                          [rehypeExternalLinks, { target: '_blank', rel: ['nofollow', 'noopener', 'noreferrer'] }]
                        ]}
                      >
                        {chat.message}
                      </ReactMarkdown>
                    </MarkdownBox>
                  </Message>
                ))}
                {streamingMessage && (
                  <Message $user="Bot" ref={streamingMessageRef}>
                    <MarkdownBox>
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[
                          [rehypeExternalLinks, { target: '_blank', rel: ['nofollow', 'noopener', 'noreferrer'] }]
                        ]}
                      >
                        {streamingMessage}
                      </ReactMarkdown>
                    </MarkdownBox>
                  </Message>
                )}
                {isLoading && !streamingMessage && (
                  <Message $user="Bot">
                    <ClipLoader color="#1565c0" size={20} />
                  </Message>
                )}
              </>
            )}
          </ChatHistory>
        </ChatBox>
      </ChatContainer>
      <InputAreaWrapper
        isMobile={isMobile}
        showWelcomeMenu={showWelcomeMenu}
        isSideMenuOpen={!isMobile && isSideMenuOpen}
      >
        <InputArea as="form" onSubmit={handleSubmit} isMobile={isMobile} isSideMenuOpen={!isMobile && isSideMenuOpen}>
          <Input
            ref={inputRef}
            as="textarea"
            value={inputText}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Message à Meoria..."
            disabled={isLoading}
            rows={1}
            isSideMenuOpen={!isMobile && isSideMenuOpen}
          />
          <Button
            type="submit"
            disabled={isLoading}
            show={inputText.trim().length > 0}
          >
            <FaArrowUp size={21} />
          </Button>
        </InputArea>
      </InputAreaWrapper>
      <PopUp
        isOpen={isPopUpOpen}
        onClose={() => setIsPopUpOpen(false)}
        message="Veuillez vous connecter pour utiliser cette fonctionnalité"
        onLogin={() => navigate('/login')}
      />
    </Container>
  );
});

export default ChatDiscussion;
