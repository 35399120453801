import styled, {css} from 'styled-components';
import { MenuButton } from '../sharedStyles';

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.$user === 'You' ? '0.75rem 1rem' : '0.2rem 0.5rem 0.5rem'};
  margin-bottom: 0.75rem;
  border-radius: ${props => props.$user === 'You' ? '1.2rem' : '1rem'};
  background-color: ${props => props.$user === 'You' ? props.theme.colors.lightGray : 'transparent'};
  color: ${props => props.theme.colors.text};
  box-shadow: ${props => props.$user === 'You' ? '0 1px 2px rgba(0, 0, 0, 0.1)' : 'none'};
  width: ${props => props.$user === 'You' ? 'fit-content' : '95%'};
  max-width: ${props => props.$user === 'You' ? '75%' : '100%'};
  text-align: left;
  margin-left: ${props => props.$user === 'You' ? 'auto' : '0'};
  height: auto;
`;

export const MenuBand = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.spacing.small};
  background-color: ${props => props.theme.colors.background};
  position: sticky;
  top: 0;
  z-index: 1;

  @media (orientation: landscape) {
    display: none; // Hide MenuBand in landscape mode (desktop)
  }
`;

export const MarkdownBox = styled.div`
  overflow-x: auto;

  & > * {
    margin-bottom: 0.625rem;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  & table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
  }

  & th, & td {
    border: 1px solid ${props => props.theme.colors.primary};
    padding: ${props => props.theme.spacing.small};
    text-align: left;
  }

  & th {
    background-color: ${props => props.theme.colors.background};
    font-weight: bold;
  }

  & tr:nth-child(even) {
    background-color: ${props => props.theme.colors.background};
  }

  & img {
    max-width: 100%;
    height: auto;
  }

  & code {
    background-color: ${props => props.theme.colors.background};
    padding: 0.125rem 0.25rem;
    border-radius: 4px;
    font-family: ${props => props.theme.fonts.main};
  }

  & pre {
    background-color: ${props => props.theme.colors.background};
    padding: ${props => props.theme.spacing.medium};
    border-radius: 4px;
    overflow-x: auto;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.background};
  position: relative;
`

;export const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    max-width: ${props => props.isSideMenuOpen && !props.isMobile ? 'calc(100% - 300px)' : '100%'};
    position: relative;
    overflow-y: auto;
    padding: ${props => props.theme.spacing.medium};
    transition: max-width 0.3s ease-in-out, margin-left 0.3s ease-in-out;
    background-color: ${({ theme }) => theme.colors.background};

    ${props => props.showWelcomeMenu && css`
      align-items: center;
    `}

    ${props => props.isMobile && css`
      position: absolute;
      top: 0;
      bottom: calc(var(--input-area-height) + var(--bottom-menu-height));
      left: 0;
      right: 0;
      padding-top: calc(${props.theme.spacing.large} + 20px);
      padding-bottom: ${props.theme.spacing.large} + 30px;
    `}
  `;

export const RestartButton = styled.button`
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.background};
  z-index: 1001;
  -webkit-tap-highlight-color: transparent;

  &:active, &:focus {
      outline: none;
    }

    ${props => props.isMobile && css`
      color: ${props => props.theme.colors.primary};
      top: 0.3125rem;
      right: 0.3125rem;
      min-width: 2rem;
      min-height: 2rem;
    `}
  `;


export const Button = styled.button`
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.background};
  border: none;
  border-radius: 25%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  right: 0.5rem;
  bottom: 0.38rem;
  transition: all 0.3s ease;
  padding: 0;
  opacity: 0;
  pointer-events: none;

  &:hover, &:active, &:focus {
      background-color: ${props => props.theme.colors.secondary};
      outline: none;
    }

  &:focus:not(:focus-visible) {
      outline: none;
    }

  ${props => props.show && css`
    opacity: 0.9;
    pointer-events: auto;
  `}

  ${props => props.isMobile && css`
    width: 2.25rem;
    height: 2.25rem;
    min-width: 2.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    position: absolute;
  `}
`;

export const ChatBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: none;
  overflow: hidden;
  width: 100%;
  max-width: ${props => props.isSideMenuOpen && !props.isMobile ? '100%' : '100%'};
  margin: 0px;
  transition: max-width 0.3s ease-in-out;
  border-radius: 12px;

  ${props => props.isMobile && css`
    border-radius: 0;
    box-shadow: none;
  `}
`;

export const ChatHistory = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: ${props => props.theme.spacing.medium};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${props => props.theme.colors.background};

  ${props => props.isMobile && css`
    max-height: calc(100vh - var(--bottom-menu-height) - var(--header-height) - var(--input-area-height));
    padding: ${props.theme.spacing.small};
  `}

 &::-webkit-scrollbar {
     width: 8px;
   }

   &::-webkit-scrollbar-thumb {
     background-color: ${({ theme }) => theme.colors.mediumGray};
     border-radius: 4px;
   }

   &::-webkit-scrollbar-thumb:hover {
     background-color: ${({ theme }) => theme.colors.darkGray};
   }
`;

export const InputAreaWrapper = styled.div`
  width: 100%;
  padding: ${props => props.theme.spacing.medium} ${props => props.theme.spacing.small};
  max-width: ${props => props.isSideMenuOpen && !props.isMobile ? 'calc(100% - 300px)' : '100%'};
  margin: 0 auto;
  position: fixed;
  left: ${props => props.isSideMenuOpen && !props.isMobile ? '300px' : '0'};
  right: 0;
  bottom: ${props => props.isMobile ? 'calc(var(--bottom-menu-height) - 10px)' : '10px'};
  background-color: ${({ theme }) => theme.colors.background};
  transform: none;

  ${props => props.isMobile && css`
    left: 0;
    right: 0;
    max-width: 100%;
    padding: ${props.theme.spacing.xsmall} ${props.theme.spacing.xsmall};
    min-height: var(--input-area-height);
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    bottom: 75px;
  `}
`;


export const InputArea = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  max-width: ${props => props.isSideMenuOpen && !props.isMobile ? '67%' : '67%'};
  margin: 0 auto;
  position: relative;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);

  ${props => props.isMobile && css`
      flex-direction: row;
      align-items: flex-end;
      width: 95%;
      max-width: 100%;
      box-shadow: none;
      padding: 0;
      position: relative;
    `}
`;

export const Input = styled.textarea`
  flex: 1;
  padding: ${({ theme }) => `${theme.spacing.medium} ${theme.spacing.medium}`};
  min-height: 3.5rem;
  max-height: 150px;
  border-radius: 25px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-family: ${({ theme }) => theme.fonts.main};
  background-color: ${({ theme }) => theme.colors.lightGray};
  color: ${({ theme }) => theme.colors.text};
  resize: none;
  overflow-y: auto;
  height: auto;
  width: calc(100% - 60px);
  max-width: 100%;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  align-self: flex-start;
  border:none


  &::placeholder {
    color: ${({ theme }) => theme.colors.mediumGray};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:focus {
    outline: none;
    border-color: none;
    box-shadow: none;
  }

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.mediumGray};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.colors.darkGray};
  }


  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.fontSizes.small};
    padding: ${({ theme }) => `${theme.spacing.small} ${theme.spacing.medium}`};
    min-height: 2.4rem;
    border-radius: 12px;
    width: auto;
    border: none;
    box-sizing: border-box;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;


export { MenuButton };