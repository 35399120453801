import React from 'react';
import { QuizContainer, QuizMessage } from './styles';

const Quiz = () => {
  return (
    <QuizContainer>
      <QuizMessage>QUIAZ est bientôt disponible</QuizMessage>
    </QuizContainer>
  );
};

export default Quiz;