import React, { useState, useRef, useEffect  } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ChatDiscussion from './components/ChatDiscussion';
import SideMenu from './components/SideMenu';
import BottomMenu from './components/BottomMenu';
import LoginPage from './components/LoginPage';
import Quiz from './components/Quiz';
import Profile from './components/Profil';
import MobileHistoryTab from './components/MobileHistoryTab';
import PseudonymForm from './components/PseudonymForm';
import { AuthProviderWithNavigate, useAuth } from './contexts/AuthContext';
import useDeviceType from './components/useDeviceType';
import theme from './theme';
import GlobalStyles from './GlobalStyles';
import { LoadingContainer, LoadingBar } from './components/LoadingStyles';
import './App.css';
import PWAInstallPrompt from './components/PWAInstallPrompt';

function App() {
  const { user, loading, isAuthenticated } = useAuth();
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [isMobileHistoryOpen, setIsMobileHistoryOpen] = useState(false);
  const { isMobile } = useDeviceType();
  const location = useLocation();
  const chatDiscussionRef = useRef();
  const [showPWAPrompt, setShowPWAPrompt] = useState(false);

  const toggleMenu = () => {
    setIsSideMenuOpen(prevState => !prevState);
  };

  const toggleMobileHistory = () => {
    setIsMobileHistoryOpen(!isMobileHistoryOpen);
  };

  const handleStartNewChat = () => {
    if (chatDiscussionRef.current) {
      chatDiscussionRef.current.resetChat();
    }
    setIsMobileHistoryOpen(false);
  };

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setShowPWAPrompt(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleDiscussionSelect = (discussion) => {
    if (chatDiscussionRef.current) {
      chatDiscussionRef.current.loadDiscussion(discussion.id);
    }
  };

  const ProtectedRoute = ({ children }) => {
    if (loading) {
      return <LoadingContainer><LoadingBar /></LoadingContainer>;
    }

    if (!isAuthenticated) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
  };

  const RootRedirect = () => {
    if (loading) {
      return <LoadingContainer><LoadingBar /></LoadingContainer>;
    }

    return isAuthenticated ? <Navigate to="/chat" replace /> : <Navigate to="/login" replace />;
  };

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <LoadingContainer>
          <LoadingBar />
        </LoadingContainer>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AuthProviderWithNavigate>
        <div className="App">
          <SideMenu
            isOpen={isSideMenuOpen}
            onToggle={toggleMenu}
            onRestartChat={handleStartNewChat}
            onDiscussionSelect={handleDiscussionSelect}
          />
          <main className={`content-area ${isMobile ? 'mobile' : 'desktop'} ${isSideMenuOpen ? 'side-menu-open' : ''}`}>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/verify-email" element={<LoginPage />} />
              <Route path="/chat" element={
                <ChatDiscussion
                  ref={chatDiscussionRef}
                  toggleMobileHistory={toggleMobileHistory}
                  isSideMenuOpen={isSideMenuOpen}
                  isMobile={isMobile}
                />
              } /> {showPWAPrompt && <PWAInstallPrompt onClose={() => setShowPWAPrompt(false)} />}
              <Route path="/quiz" element={<Quiz />} />
              <Route path="/profile" element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              } />
              <Route path="/set-pseudonym" element={
                <ProtectedRoute>
                  <PseudonymForm />
                </ProtectedRoute>
              } />
              <Route path="/" element={<RootRedirect />} />
            </Routes>
          </main>
          {isMobile && (
            <>
              <BottomMenu user={user} />
              {location.pathname === '/chat' && (
                <MobileHistoryTab
                  isOpen={isMobileHistoryOpen}
                  onClose={toggleMobileHistory}
                  discussions={[]}
                  onStartNewChat={handleStartNewChat}
                />
              )}
            </>
          )}
        </div>
      </AuthProviderWithNavigate>
    </ThemeProvider>
  );
}

export default App;
