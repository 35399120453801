import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { ChatHistoryContainer, HistoryItem, RenameInput, IconButton, DiscussionName } from './styles';
import { useAuth } from '../../contexts/AuthContext';
import { Edit, Check, X } from 'lucide-react';

const ChatHistory = ({ onDiscussionClick }) => {
  const [discussions, setDiscussions] = useState([]);
  const [error, setError] = useState(null);
  const { isAuthenticated, token, user } = useAuth();
  const [renamingId, setRenamingId] = useState(null);
  const [newName, setNewName] = useState('');

  const fetchDiscussions = useCallback(async () => {
    if (isAuthenticated && token) {
      try {
        console.log("Fetching discussions...");
        const config = {
          method: 'get',
          url: `${process.env.REACT_APP_BACKEND_URL}/inference/get_user_discussions/`,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };
        console.log("Request config:", JSON.stringify(config, null, 2));
        const response = await axios(config);
        console.log("Discussions fetched:", response.data);
        setDiscussions(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching discussions:', error);
        console.error('Error response:', error.response);
        setDiscussions([]);
        if (error.response) {
          if (error.response.status === 401) {
            setError("Erreur d'authentification. Veuillez vous reconnecter.");
          } else {
            setError(`Erreur lors de la récupération des discussions: ${error.response?.data?.error || 'Erreur inconnue'} (Status: ${error.response?.status})`);
          }
        } else if (error.request) {
          setError("Erreur de connexion au serveur. Veuillez réessayer plus tard.");
        } else {
          setError("Erreur inattendue. Veuillez réessayer.");
        }
      }
    } else {
      console.log("User is not logged in or token is missing");
      setDiscussions([]);

    }
  }, [isAuthenticated, token]);

  useEffect(() => {
    console.log("ChatHistory component rendered");
    console.log("isAuthenticated:", isAuthenticated);
    console.log("user:", user);

    fetchDiscussions();
  }, [isAuthenticated, token, user, fetchDiscussions]);

    const startRenaming = (discussion) => {
      setRenamingId(discussion.id);
      setNewName(discussion.name);
    };

    const handleRename = async (discussionId) => {
      if (isAuthenticated && token && newName.trim() !== '') {
        try {
          const config = {
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_URL}/inference/rename_discussion/`,
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            data: {
              discussion_id: discussionId,
              new_name: newName.trim(),
            },
          };
          const response = await axios(config);
          if (response.data.success) {
            setDiscussions(prevDiscussions =>
              prevDiscussions.map(d =>
                d.id === discussionId ? { ...d, name: newName.trim() } : d
              )
            );
            fetchDiscussions();
          }
        } catch (error) {
          console.error('Error renaming discussion:', error);
          setError(`Erreur lors du renommage de la discussion: ${error.response?.data?.error || 'Erreur inconnue'}`);
        }
      } else if (newName.trim() === '') {
        setError("Le nom de la discussion ne peut pas être vide.");
      }
      setRenamingId(null);
    };

    const handleKeyPress = (event, discussionId) => {
      if (event.key === 'Enter') {
        handleRename(discussionId);
      }
    };

    const cancelRenaming = () => {
      setRenamingId(null);
      setNewName('');
    };

    // Add console logging for discussions
    useEffect(() => {
      console.log('Current discussions:', discussions);
    }, [discussions]);

    return (
      <ChatHistoryContainer>
        {error && <p>{error}</p>}
        {!isAuthenticated ? (
          <p>Veuillez vous connecter pour voir votre historique de discussion.</p>
        ) : discussions.length === 0 ? (
          <p>Aucune discussion récente</p>
        ) : (
          discussions.map((discussion) => {
            console.log('Rendering discussion:', discussion); // Log each discussion
            return (
              <HistoryItem key={discussion.id}>
                {renamingId === discussion.id ? (
                  <>
                    <RenameInput
                      value={newName}
                      onChange={(e) => setNewName(e.target.value)}
                      onKeyPress={(e) => handleKeyPress(e, discussion.id)}
                    />
                    <IconButton onClick={() => handleRename(discussion.id)}>
                      <Check size={18} />
                    </IconButton>
                    <IconButton onClick={cancelRenaming}>
                      <X size={18} />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <DiscussionName onClick={() => onDiscussionClick(discussion)}>
                      {discussion.name || `Discussion ${discussion.id}`}
                    </DiscussionName>
                    <IconButton onClick={() => startRenaming(discussion)}>
                      <Edit size={18} />
                    </IconButton>
                  </>
                )}
              </HistoryItem>
            );
          })
        )}
      </ChatHistoryContainer>
    );
  };

  export default ChatHistory;