import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  padding: ${props => props.theme.spacing.large} ${props => props.theme.spacing.medium};
  background-color: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10vh;
  height: 80vh;
`;

export const Title = styled.h2`
  color: ${props => props.theme.colors.primary};
  margin-bottom: 5vh;
  font-size: ${props => props.theme.fontSizes.xlarge};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
`;

export const Button = styled.button`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
  background-color: transparent;
  color: ${props => props.theme.colors.primary};
  border: 2px solid ${props => props.theme.colors.primary};
  border-radius: ${props => props.theme.spacing.small};
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes.medium};
  margin-bottom: ${props => props.theme.spacing.medium};
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${props => `${props.theme.colors.primary}1A`}; // 10% opacity
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const ButtonText = styled.span`
  flex-grow: 1;
  text-align: center;
`;

export const EmailLoginContainer = styled.div`
  width: 100%;
  max-width: 300px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Input = styled.input`
  padding: ${props => props.theme.spacing.small};
  margin-bottom: ${props => props.theme.spacing.small};
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: ${props => props.theme.spacing.small};
  font-size: ${props => props.theme.fontSizes.medium};
`;

export const ReturnButton = styled.button`
  position: absolute;
  top: ${props => props.theme.spacing.large};
  left: ${props => props.theme.spacing.large};
  background: none;
  border: none;
  font-size: ${props => props.theme.fontSizes.xlarge};
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
`;

export const Logo = styled.img`
  width: 1.5em;
  height: 1.5em;
  margin-right: ${props => props.theme.spacing.small};
`;

export const Message = styled.p`
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.background};
  padding: ${props => props.theme.spacing.small};
  border-radius: ${props => props.theme.spacing.small};
  margin-top: ${props => props.theme.spacing.medium};
  text-align: center;
  width: 100%;
  max-width: 300px;
  border: 1px solid ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.small};
`;

export const CountdownText = styled.p`
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.small};
  text-align: center;
  margin-top: ${props => props.theme.spacing.small};
`;

export const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
`;

export const PopupButton = styled(Button)`
  margin: 10px;
`;