import styled from 'styled-components';
import { FaDoorOpen } from 'react-icons/fa'; // Importing the door icon

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing.large};
  max-width: 600px;
  margin: 0 auto;
  height: 100vh; /* Full height for center alignment */
  position: relative; /* For positioning the Logout button */
  background-color: ${props => props.theme.colors.background};
`;

export const Title = styled.h1`
  font-size: ${props => props.theme.fontSizes.xlarge};
  color: ${props => props.theme.colors.text};
  margin-bottom: ${props => props.theme.spacing.large};
`;

export const UserInfo = styled.div`
  background-color: ${props => props.theme.colors.background};
  border-radius: ${props => props.theme.spacing.small};
  padding: ${props => props.theme.spacing.large};
  width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  p {
    margin: ${props => props.theme.spacing.small} 0;
    font-size: ${props => props.theme.fontSizes.medium};
    color: ${props => props.theme.colors.text};
  }
`;

export const LogoutButton = styled.button`
  position: absolute;
  top: ${props => props.theme.spacing.large};
  right: ${props => props.theme.spacing.large};
  background: none;
  border: none;
  cursor: pointer;
  padding: ${props => props.theme.spacing.small};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: ${props => props.theme.spacing.small};
  }
`;

export const LogoutIcon = styled(FaDoorOpen)`
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fontSizes.xlarge};

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: ${props => props.theme.fontSizes.large};
  }
`;

export const LoginButton = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.background};
  border: none;
  border-radius: ${props => props.theme.spacing.small};
  padding: ${props => props.theme.spacing.medium};
  font-size: ${props => props.theme.fontSizes.medium};
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.colors.secondary};
  }
`;