import styled from 'styled-components';
import theme from '../../theme';
import { MenuButton as SharedMenuButton } from '../sharedStyles';



export const MenuButton = styled(SharedMenuButton)`
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  &:active, &:focus {
    outline: none;
    background-color: transparent;
  }
  &&& {
    background: none;
    box-shadow: none;
  }
`;
export const SideMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  background-color: ${theme.colors.background};
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  transform: translateX(${props => props.$isOpen ? '0' : '-100%'});
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    width: 75%;
    max-width: 300px;
  }
`;


export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.small};
  align-items: center;
  background-color: ${theme.colors.background};
  border-bottom: 1px solid ${theme.colors.primary};
`;

export const RestartButton = styled(MenuButton)`
  margin-left: auto; // This will push the button to the right
`;

export const TopSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const BottomNavList = styled.div`
  padding: ${theme.spacing.small};
  border-top: 1px solid ${theme.colors.primary};
`;

export const NavItem = styled.div`
  padding: ${theme.spacing.small} 0;

  button {
    background: none;
    border: none;
    font-size: ${theme.fontSizes.medium};
    cursor: pointer;
    width: 100%;
    text-align: left;
    color: ${theme.colors.text};
    font-family: ${theme.fonts.main};
    padding: ${theme.spacing.xsmall} ${theme.spacing.small};
    border-radius: 4px;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: ${theme.colors.lightGray};
    }
  }
`;

export const HistoryContainer = styled.div`
  flex: 1;
  background-color: ${theme.colors.veryLightGray};
  border-radius: ${theme.spacing.small};
  margin: ${theme.spacing.small};
  overflow-y: auto;
`;

export const MainContentWrapper = styled.div`
  transition: margin-left 0.3s ease-in-out;
  margin-left: ${props => props.$isOpen && !props.$isMobile ? '300px' : '0'};
  width: ${props => props.$isOpen && !props.$isMobile ? 'calc(100% - 300px)' : '100%'};
`;