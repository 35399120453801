import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Title,
  ButtonContainer,
  Button,
  ButtonText,
  Input,
  Form,
  EmailLoginContainer,
  ReturnButton,
  Message,
  CountdownText
} from './styles';
import { useAuth } from '../../contexts/AuthContext';

const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [showEmailLogin, setShowEmailLogin] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [countdown, setCountdown] = useState(0);
  const [isVerifying, setIsVerifying] = useState(false);
  const location = useLocation();
  const [showVerificationInput, setShowVerificationInput] = useState(false);
  const { login, error: authError } = useAuth();
  const [verificationLink, setVerificationLink] = useState('');
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const handleLoginSuccess = useCallback(async (tokens, method, is_new_user = false) => {
    console.log("handleLoginSuccess called with tokens:", tokens, "method:", method);
    console.log('Stored token:', localStorage.getItem('authToken'));

    try {
      await login(tokens, method, is_new_user);
    } catch (error) {
      console.error('Error during login:', error);
      setVerificationMessage('Une erreur est arrivée. Veuillez réessayer ou envoyer un message au support si le problème persiste.');
    }
  }, [login]);

   const handleVerificationLinkSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      try {
        const url = new URL(verificationLink);
        const token = url.searchParams.get('token');
        if (!token) {
          throw new Error('Lien invalide. Veuillez vérifier le lien.');
        }
        await verifyEmailToken(token);
      } catch (error) {
        console.error('Error verifying email:', error);
        setVerificationMessage(error.message || 'Lien invalide. Veuillez vérifier le lien.');
      } finally {
        setIsLoading(false);
      }
    };


  const verifyEmailToken = useCallback(async (token) => {
    setIsVerifying(true);
    setIsLoading(true);
    try {
      console.log('Verifying token:', token);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/email/verify_code/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
      console.log('Server response:', data);

      if (!response.ok) {
        throw new Error(data.error || `HTTP error! status: ${response.status}`);
      }

      if (data.access && data.refresh) {
        // Create a tokens object
        const tokens = {
          access: data.access,
          refresh: data.refresh,
        };
        await handleLoginSuccess(tokens, 'email', data.is_new_user);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      setVerificationMessage(error.message || 'Lien expiré ou invalide. Veuillez réessayer.');
    } finally {
      setIsLoading(false);
      setIsVerifying(false);
    }
  }, [handleLoginSuccess]);

const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/email/request_code/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await response.json();
      setVerificationMessage('Email de vérification envoyé. Regarde ta boite mail et clique sur le lien de vérification.');
      setCountdown(180);
      if (isIOS) {
        setShowVerificationInput(true);
      }
    } catch (error) {
      console.error('Error requesting verification email:', error);
      setVerificationMessage('Une erreur est arrivée. Veuillez réessayer.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      verifyEmailToken(token);
    }
  }, [location, verifyEmailToken]);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setVerificationMessage('');
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [countdown]);

  useEffect(() => {
    if (authError) {
      setVerificationMessage(authError);
    }
  }, [authError]);

  if (isVerifying) {
    return (
      <Container>
        <Message>Nous vérifions votre email... Veuillez patienter.</Message>
      </Container>
    );
  }

  if (showEmailLogin) {
    return (
      <Container>
        <ReturnButton onClick={() => {
          setShowEmailLogin(false);
          setShowVerificationInput(false);
        }}>&larr;</ReturnButton>
        <EmailLoginContainer>
          {!showVerificationInput ? (
            <Form onSubmit={handleEmailSubmit}>
              <Input
                type="email"
                autoComplete="email"
                placeholder="Entrer votre email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={countdown > 0}
                required
              />
              <Button type="submit" disabled={isLoading || countdown > 0}>
                {isLoading ? 'Chargement...' : 'Envoyer le lien de vérification'}
              </Button>
            </Form>
          ) : isIOS && (
            <Form onSubmit={handleVerificationLinkSubmit}>
              <Input
                type="text"
                placeholder="Coller votre lien de vérification"
                value={verificationLink}
                onChange={(e) => setVerificationLink(e.target.value)}
                required
              />
              <Button type="submit" disabled={isLoading}>
                {isLoading ? 'Chargement...' : 'Vérifier le lien'}
              </Button>
            </Form>
          )}
          {verificationMessage && <Message>{verificationMessage}</Message>}
          {countdown > 0 && (
            <CountdownText>
              Vous pourrez renvoyer un code dans: {Math.floor(countdown / 60)}:
              {(countdown % 60).toString().padStart(2, '0')}
            </CountdownText>
          )}
        </EmailLoginContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Title>Bienvenue</Title>
      <ButtonContainer>
        <Button onClick={() => setShowEmailLogin(true)}>
          <ButtonText>Se connecter avec un email</ButtonText>
        </Button>
      </ButtonContainer>
      {verificationMessage && <Message>{verificationMessage}</Message>}
    </Container>
  );
};

export default LoginPage;
