import styled from 'styled-components';

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: ${props => props.theme.colors.background};
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: ${props => props.theme.spacing.large};
  background-color: transparent;
  border-radius: ${props => props.theme.spacing.small};
`;

export const Input = styled.input`
  width: 100%;
  padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
  margin-bottom: ${props => props.theme.spacing.small};
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: ${props => props.theme.spacing.small};
  font-size: ${props => props.theme.fontSizes.medium};
  box-sizing: border-box;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
  background-color: transparent;
  color: ${props => props.theme.colors.primary};
  border: 2px solid ${props => props.theme.colors.primary};
  border-radius: ${props => props.theme.spacing.small};
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes.medium};
  margin-bottom: ${props => props.theme.spacing.medium};
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${props => `${props.theme.colors.primary}1A`}; // 10% opacity
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const ErrorMessage = styled.p`
  margin-top: ${props => props.theme.spacing.medium};
  color: ${props => props.theme.colors.error || 'red'}; // Fallback to red if error color is not defined
  font-size: ${props => props.theme.fontSizes.small};
  text-align: center;
`;